import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Image from "../components/image"
import "../styles/styles.css"
import SEO from "../components/seo"
import Header from "../components/header/header.component"
import Footer from "../components/footer/footer.component"

export const query = graphql`
  query($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D MMMM YYYY, HH:mm")
        path
        blogtitle
        author
        metaDescription
        blogimage
      }
    }
  }
`

const Blog = props => {
  const [url, setUrl] = useState()
  useEffect(() => {
    // Update the document title using the browser API
    setUrl(window.location.href ? window.location.href : "")
  })

  return (
    <div>
      <SEO
        title={props.data.markdownRemark.frontmatter.blogtitle}
        description={props.data.markdownRemark.frontmatter.metaDescription}
        url={
          "https://nibrasplay.co.id/blog/" +
          props.data.markdownRemark.frontmatter.path
        }
        image={
          "https://nibrasplay.co.id/" +
          props.data.markdownRemark.frontmatter.blogimage
        }
      />
      <Header />
      <div className="container-policy">
        <div className="policy">
          <h1>{props.data.markdownRemark.frontmatter.blogtitle}</h1>
          <p>{props.data.markdownRemark.frontmatter.author} | {props.data.markdownRemark.frontmatter.date}</p>
          {/* <Image
            alt="featured-image"
            name={props.data.markdownRemark.frontmatter.blogimage.replace(
              "images/",
              ""
            )}
          /> */}
        </div>

        <div
          className="policy-content"
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        />
      </div>

      <Footer />
    </div>
  )
}

export default Blog
